import { useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [formData, setFormData] = useState({});
  const [msg, setMsg] = useState(''); // Voor het weergeven van succes- of foutberichten

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset de berichten voordat je een nieuw verzoek indient
    setMsg('');

    // Voorbeeld van het verzenden van een POST-verzoek met Axios
    axios.post('https://treeoskiframes.website/infos/index.php', formData, {
      maxRedirects: 5 // Volgt maximaal 5 omleidingen
    })
      .then(response => {
        console.log('Gegevens succesvol verzonden', response);
        // Als het verzoek succesvol is, toon dan een succesbericht
        setMsg('Gegevens succesvol verzonden!');
      })
      .catch(error => {
        console.error('Fout bij het verzenden van gegevens', error);
        // Als het verzoek mislukt, toon dan een foutbericht
        setMsg('Er is een fout opgetreden bij het verzenden van de gegevens. Probeer het opnieuw.');
      });
  }

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input required className='form-control mt-2' value={formData.full_name || ''} type="text" placeholder="Naam en voornaam" onChange={(e) => setFormData({...formData, full_name: e.target.value})} />
        <select required className='form-control mt-2' name='fonction' value={formData.fonction || null} onChange={(e)=>{setFormData({...formData, fonction: e.target.value})}} class="form-control">
            <option value={null}>Functie in het bedrijf</option>
            <option value="Directeur">Directeur</option>
            <option value="Gérant">Gerant</option>
            <option value="Commercial">Commercieel</option>
            <option value="Marketing / communication">Marketing / Communicatie</option>
            <option value="Responsable achats">Inkoopverantwoordelijke</option>
            <option value="Responsable production">Productieverantwoordelijke</option>
            <option value="Logistique">Logistiek</option>
            <option value="RH">HR</option>
            <option value="Responsable financier">Financieel verantwoordelijke</option>
            <option value="Autre">Anders</option>
          </select>
        <input required className='form-control mt-2' value={formData.email || ''} type="email" placeholder="E-mail" onChange={(e) => setFormData({...formData, email: e.target.value})} />
        <input required className='form-control mt-2' value={formData.phone || ''} type="tel" placeholder="Telefoon" onChange={(e) => setFormData({...formData, phone: e.target.value})} />
        <input required type='text' placeholder='Naam van de zaak en naam van het bedrijf' className='form-control mt-2' value={formData.company_name || ''} onChange={(e)=>{setFormData({...formData, company_name: e.target.value})}}/>
        <input required className='form-control mt-2' value={formData.tva_number || ''} type="text" placeholder="BTW-nummer" onChange={(e) => setFormData({...formData, tva_number: e.target.value})} />
        <select value={formData.company_type || null} onChange={(e)=>{setFormData({...formData, company_type: e.target.value})}} name='company_type' class="form-control mt-2">
            <option value={null}>Bedrijfstype</option>
            <option value="Friterie">Frietkraam</option>
            <option value="Grossiste">Groothandel</option>
            <option value="Boulangerie">Bakery</option>
            <option value="International">Internationaal</option>
            <option value="Snack plus de 10 places assises">Snack met meer dan 10 zitplaatsen</option>
            <option value="Kebab">Kebab</option>
            <option value="Sandwicherie">Sandwicherie</option>
            <option value="Brasserie">Brasserie</option>
            <option value="Fritkot (à emporter)">Fritkot (om mee te nemen)</option>
            <option value="Ambulant">Ambulant</option>
            <option value="Restaurant">Restaurant</option>
            <option value="Collectivité">Collectiviteit</option>
            <option value="Autre">Anders</option>
        </select>
        <input required min={1000} max={9999} className='form-control mt-2' value={formData.zip || ''} type="number" placeholder="Postcode" onChange={(e) => setFormData({...formData, zip: e.target.value})} />
        <select value={formData.client || 'Al reeds klant'}  onChange={(e)=>{setFormData({...formData, client: e.target.value})}}  name='client' class="form-control mt-2">
          <option value="Déjà client">Al reeds klant</option>
          <option value="Gregory Malfait">Gregory Malfait</option>
          <option value="Jacky Fernandez">Jacky Fernandez</option>
          <option value="Danielle Minet">Danielle Minet</option>
          <option value="Eric Muller">Eric Muller</option>
          <option value="Yannick Genot">Yannick Genot</option>
          <option value="Joseph Callari">Joseph Callari</option>
          <option value="Hugo Claude">Hugo Claude</option>
          <option value="Frederik Laleeuwe (Vlaanderen">Frederik Laleeuwe (Vlaanderen)</option>
          <option value="Gabriel Aragona">Gabriel Aragona</option>
        </select>
        <select value={formData.no_client || "Geen Klant"} class="form-control mt-2" onChange={(e)=>{setFormData({...formData, no_client: e.target.value})}}>
          <option value="Pas Client">Geen klant</option>
          <option value="Spontini">Spontini</option>
          <option value="Bosteels">Bosteels</option>
          <option value="Noyez">Noyez</option>
          <option value="Foods&amp;Drinks (Asdis)">Foods&amp;Drinks (Asdis)</option>
          <option value="Carry">Carry</option>
          <option value="Desprit">Desprit</option>
          <option value="Delisalade">Delisalade</option>
          <option value="Hostfood">Hostfood</option>
          <option value="Ozfood">Ozfood</option>
          <option value="Erka">Erka</option>
          <option value="Ertek">Ertek</option>
          <option value="Bitvest">Bitvest</option>
          <option value="Solucious">Solucious</option>
          <option value="Conways">Conways</option>
          <option value="Ramhoreca">Ramhoreca</option>
          <option value="Trendy">Trendy</option>
          <option value="Divers">Divers</option>
        </select>
        <button className='send mt-2' type='submit'>
          VERZENDEN
        </button>
        <span style={{color: 'brown'}}>
          {msg}
        </span>
      </form>
    </div>
  );
}

export default App;